<template>
  <div>
    <v-card>
      <v-card-title class="title">
        <v-layout row wrap>
          <v-flex xs12>
            <VUserSearchField
              @onSelectUser="onSelectUser"
              @onClear="onClear"
              :companyId="schoolId"
              label="Start typing to search (Mobile/Email/Name) *"
            ></VUserSearchField>
          </v-flex>
          <v-flex xs12>
            <v-select
              outlined
              dense
              v-model="selectedRole"
              :items="roles"
              label="Select Role*"
              multiple
              class="pt-0"
            >
              <!-- :rules="[(v) => !!v || 'Role Field is required']" -->
              <v-list-item slot="prepend-item" ripple @click="toggle">
                <v-list-item-action>
                  <v-icon
                    :color="selectedRole.length > 0 ? 'indigo darken-4' : ''"
                    >{{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item>
              <v-divider slot="prepend-item" class="mt-2"></v-divider>
              <v-divider slot="append-item" class="mb-2"></v-divider>
            </v-select>
          </v-flex>
          <v-flex xs12 v-if="user !== ''">
              <v-row>
                  <v-col sm="6">
                  <h3>Current Role</h3> 
                      <v-card v-for="(value,index) in user.roles" :key="index">
                          <span > 
                              {{value.name}}
                          </span>
                      </v-card>
                  </v-col>
                  <v-col sm="6">
                  <h3>Current Comapny</h3> 
                      <v-card v-for="(value,ind) in companies" :key="ind">
                          <span > 
                              {{value.name}}
                          </span>
                      </v-card>
                  </v-col>
              </v-row>
          </v-flex>
          <v-flex xs12 class="text-right">
            <v-btn color="success" text @click="assignUser()">Save</v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
    </v-card>

  </div>
</template>
<script>
import VUserSearchField from "../../../../../components/app/VUserSearchField.vue";

export default {
  components: { VUserSearchField },
  props: ["schoolId"],
  data() {
    return {
      user: "",
      selectedRole: "",
      roleLoading: "",
      roles: [],
      companies:''
    };
  },
  mounted() {
    // this.fetchRoles();
  },
  computed: {
    selectAllRoles() {
      return this.selectedRole.length === this.roles.length;
    },
    selectFewRoles() {
      return this.selectedRole.length > 0 && !this.selectAllRoles;
    },
    icon() {
      if (this.selectAllRoles) return "check_box";
      if (this.selectFewRoles) return "indeterminate_check_box";
      return "add_box";
    },
  },
  methods: {
    onSelectUser(user) {
      this.user = user;
      this.fetchRoles();
      this.getUserCompany();
    },
    onClear(){
      this.resetData();
    },
    fetchRoles() {
      this.roleLoading = true;
      this.$rest
        .get("/api/roles?rowsPerPage=50&sortBy=name")
        .then(({ data }) => {
          let allRoles = [];
          data.data.map(function (item) {
            allRoles.push({
              id: item.id,
              slug: item.slug,
              name: item.name,
              text: item.name,
              value: item.id,
            });
          });
          const userCurrentRoles = this.user.roles.map(item=>item.id);
          const filteredRoles = allRoles.filter((rl) => {
            return !userCurrentRoles.includes(rl.id);            
          });
          this.roles = filteredRoles;
        })
        .finally(() => {
          this.roleLoading = false;
        });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllRoles) {
          this.selectedRole = [];
        } else {
          this.selectedRole = this.roles.map((data) => {
            return data.value;
          });
        }
      });
    },
    getUserCompany(){
        this.$rest.get('/api/get-user-company/'+this.user.id).then((res)=>{
            this.companies = res.data
        });
    },
    
    assignUser() {
      if(this.user.id == '' || this.selectedRole== ''){
          this.$events.fire('notification', {message: "Please Fill the required value", status: 'error'});
        
      }else{
        this.$rest.post('/api/assignUser',{
            id:this.user.id,
            roles:this.selectedRole,
            companyId:this.schoolId
        })
        .then((res)=>{
          this.resetData();
          this.$events.fire('notification', {message: res.data.message, status: 'success'});
        })
        .catch((err)=>{
          this.resetData();
            this.$events.fire('notification', {message: err.response.data.message, status: 'error'})
        })

      }
    },
    resetData(){
      this.user= "";
      this.selectedRole= "";
      this.roleLoading= "";
      this.roles= [];
      this.companies="";
    },
  },
};
</script>